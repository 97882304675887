import { Injectable } from '@angular/core';
import { DynamicType, SocieteComposant } from '@get/api-interfaces';
import { DbService } from '@get/services/storage';
import { SocieteComposantApiService } from '@get/store/api-services';
import { transformArrayToObject } from '@utils';
import { ReplaySubject, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DescriptionsService {
  public descriptions: DynamicType<SocieteComposant> = {};
  public descriptionsDict$ = new ReplaySubject<DynamicType<SocieteComposant>>(1);

  constructor(private societeComposantApiService: SocieteComposantApiService, private dbService: DbService) {}

  public async loadDescriptions(): Promise<void> {
    const descriptions = await firstValueFrom(this.societeComposantApiService.getSocieteComposantDescriptions());
    this.refreshDescriptions(descriptions);
  }

  public async refreshDescriptions(descriptions: SocieteComposant[]): Promise<void> {
    this.descriptions = transformArrayToObject(descriptions, { key: 'idSocieteComposant' });
    this.descriptionsDict$.next(this.descriptions);
    const databaseCollections = await this.dbService.getDatabaseCollection();
    this.dbService.updateIndexedDb(
      databaseCollections,
      'societe-composant-descriptions',
      descriptions,
      'idSocieteComposant'
    );
  }
}
