import { Injectable } from '@angular/core';
import { DynamicType, Organisation, User } from '@get/api-interfaces';
import { MyDatabaseCollections } from '@get/interfaces';
import { DescriptionsService } from '@get/services/descriptions';
import { DbService, StorageService } from '@get/services/storage';
import { DroitGeneratedActions, FichierGeneratedActions, OrganisationGeneratedActions } from '@get/store/actions';
import { DroitApiService, FichierApiService, SocieteComposantApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import {
  DroitModel,
  SocieteModel,
  SocietePatrimoineHierarchieModel,
  SocieteProfilModel,
  UserModel,
  UserPatrimoineModel
} from '@get/store/selectors-model';
import { OrganisationService, UserService } from '@get/store/services';
import { Action, Store } from '@ngrx/store';
import { parseJwt } from '@utils';
import { RxCollection } from 'rxdb';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { concatMap, first, pairwise, startWith, takeUntil, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AppResolver {
  private databaseCollections: MyDatabaseCollections;

  // Sert à couper les subscribes si le resolve est appelé à nouveau
  private resolveSubject$ = new Subject<void>();

  // Servent à accéder à l'app lorsque ces stores sont remplis car ils sont nécessaires au bon fonctionnement de l'app
  private orgasSubject$ = new ReplaySubject<void>(1);
  private usersSubject$ = new ReplaySubject<void>(1);

  constructor(
    private organisationService: OrganisationService,
    private storageService: StorageService,
    private userService: UserService,
    private dbService: DbService,
    private droitApiService: DroitApiService,
    private fichierApiService: FichierApiService,
    private societeComposantApiService: SocieteComposantApiService,
    private store$: Store<AppState>,
    private descriptionsService: DescriptionsService
  ) {}

  private async handleOrganisations(idUser: number): Promise<void> {
    this.handleIndexedDbRefillingStorageOrganisation();

    this.organisationService
      .selectAllOrganisations({
        include: [{ model: SocieteModel, include: [SocietePatrimoineHierarchieModel] }, UserModel]
      })
      .pipe(
        takeUntil(this.resolveSubject$),
        tap((organisations: Organisation[]) => {
          this.organisationService.setCurrentOrganisation(organisations[0]);
          this.orgasSubject$.next();
        })
      )
      .subscribe();

    this.userService
      .selectOneUser(idUser, { include: [UserPatrimoineModel, { model: SocieteProfilModel, include: [DroitModel] }] })
      .pipe(
        takeUntil(this.resolveSubject$),
        tap((user: User) => {
          this.userService.updateCurrentUser(user);
          this.usersSubject$.next();
        })
      )
      .subscribe();

    setTimeout(() => {
      this.organisationService.getUserOrganisation(idUser, this.databaseCollections);
    }, 0);
  }

  private async handleIndexedDbRefillingStorageDescriptions(): Promise<void> {
    (this.databaseCollections['societe-composant-descriptions'] as RxCollection)
      .find()
      .$.pipe(
        takeUntil(this.resolveSubject$),
        startWith([]),
        pairwise(),
        tap(([previous, current]) => {
          const previousValues = previous?.map(el => el.toJSON());
          const currentValues = current?.map(el => el.toJSON());
          const keysComparison =
            previous?.length !== current?.length || JSON.stringify(previousValues) !== JSON.stringify(currentValues);
          if (currentValues.length && keysComparison) {
            this.descriptionsService.refreshDescriptions(
              currentValues.map(el => ({
                ...el,
                idSocieteComposant: +el.idSocieteComposant
              }))
            );
          }
        })
      )
      .subscribe();
    setTimeout(() => {
      this.descriptionsService.loadDescriptions();
    }, 0);
  }

  private async handleIndexedDbRefillingStorageOrganisation(): Promise<void> {
    this.databaseCollections['organisations']
      .find()
      .$.pipe(
        takeUntil(this.resolveSubject$),
        startWith([]),
        pairwise(),
        tap(([previous, current]) => {
          const previousValues = previous?.map(el => el.toJSON());
          const currentValues = current?.map(el => el.toJSON());
          const keysComparison =
            previous?.length !== current?.length || JSON.stringify(previousValues) !== JSON.stringify(currentValues);
          if (
            currentValues?.length === previousValues?.length &&
            currentValues?.length === 1 &&
            currentValues[0]?.uuid !== previousValues[0]?.uuid
          ) {
            window.location.reload();
          } else if (currentValues.length && keysComparison) {
            this.store$.dispatch(
              OrganisationGeneratedActions.normalizeManyOrganisationsAfterUpsert({
                organisations: currentValues.map(el => ({
                  ...el,
                  idOrganisation: +el.idOrganisation
                }))
              })
            );
          }
        })
      )
      .subscribe();
  }

  // TODO: Déplacer les 2 fonctions dans des utils ?! (pour l'instant pas nécessaire mais si besoin le faire)
  private async handleIndexedDbRefillingStorage<T>(params: {
    tableKey: keyof MyDatabaseCollections;
    storeKey: keyof DynamicType<T>;
    primaryKey: keyof T;
    normalizationMethod: (param: DynamicType<T[]>) => Action;
  }): Promise<void> {
    (this.databaseCollections[params.tableKey] as RxCollection)
      .find()
      .$.pipe(
        takeUntil(this.resolveSubject$),
        startWith([]),
        pairwise(),
        tap(([previous, current]) => {
          const previousValues = previous?.map(el => el.toJSON());
          const currentValues = current?.map(el => el.toJSON());
          const keysComparison =
            previous?.length !== current?.length || JSON.stringify(previousValues) !== JSON.stringify(currentValues);
          if (currentValues.length && keysComparison) {
            this.store$.dispatch(
              params.normalizationMethod({
                [params.storeKey]: currentValues.map(el => ({
                  ...el,
                  [params.primaryKey]: +el[params.primaryKey]
                }))
              })
            );
          }
        })
      )
      .subscribe();
  }

  private async handleIndexedDbRoute<T>(params: {
    tableKey: keyof MyDatabaseCollections;
    storeKey: keyof DynamicType<T>;
    primaryKey: keyof T;
    normalizationMethod: (param: DynamicType<T[]>) => Action;
    shouldCleanup?: boolean;
    apiMethod: () => Observable<T[]>;
  }): Promise<void> {
    this.handleIndexedDbRefillingStorage(params);

    setTimeout(() => {
      params
        .apiMethod()
        .pipe(
          first(),
          tap(async elements => {
            if (elements?.length) {
              this.store$.dispatch(
                params.normalizationMethod({
                  [params.storeKey]: elements
                })
              );
              this.dbService.updateIndexedDb(this.databaseCollections, params.tableKey, elements, params.primaryKey);
            }
          })
        )
        .subscribe();
    }, 0);
  }

  private async handleInitialisationRoutes(idUser: number): Promise<void> {
    this.databaseCollections = await this.dbService.getDatabaseCollection();

    this.handleOrganisations(idUser);

    setTimeout(() => {
      this.handleIndexedDbRoute({
        tableKey: 'droits',
        primaryKey: 'idDroit',
        storeKey: 'droits',
        shouldCleanup: true,
        normalizationMethod: DroitGeneratedActions.normalizeManyDroitsAfterUpsert,
        apiMethod: this.droitApiService.getDroits.bind(this.droitApiService)
      });
      this.handleIndexedDbRoute({
        tableKey: 'fichiers',
        primaryKey: 'idFichier',
        storeKey: 'fichiers',
        shouldCleanup: true,
        normalizationMethod: FichierGeneratedActions.normalizeManyFichiersAfterUpsert,
        apiMethod: this.fichierApiService.getFichiers.bind(this.fichierApiService)
      });
      this.handleIndexedDbRefillingStorageDescriptions();
    }, 0);
  }

  public resolve(): Observable<void> {
    this.usersSubject$ = new ReplaySubject<void>();
    this.orgasSubject$ = new ReplaySubject<void>();

    this.resolveSubject$.next();
    const decodedToken = parseJwt(this.storageService.getLocal('login')?.token);
    const idUser = decodedToken?.idUser;
    if (!idUser) {
      throw new Error('No idUser in token');
    }
    this.userService.setActiveUsers([idUser]);
    this.handleInitialisationRoutes(idUser);
    return this.orgasSubject$.pipe(concatMap(() => this.usersSubject$));
  }
}
